import React from 'react';
import Routers from './Router/Router';

function App() {
  return (
    // <div>
      <Routers />
    // </div>
  );
}

export default App;
