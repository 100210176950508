import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import '../Styles/Containers/Product.css'

const Products = () => {

    const globalUrl = 'https://testing.faceup.id';
    const [productBanner, setProductBanner] = useState(null);
    const [productList, setProductList] = useState(null);
    const navigate = useNavigate();

    const handleProductClick = (product) => {
        navigate(`/ProductDetail/${product.attributes.Product_Title}`, { state: { product } });
    };    

    useEffect(() => {
        const fetchProductBanner = async () => {
            try {
                const response = await fetch(`${globalUrl}/api/product-pages?populate=*`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setProductBanner(data);
            } catch (error) {
                console.error('Error fetching home data:', error);
            }
        };

        const fetchProductList = async () => {
            try {
                const response = await fetch(`${globalUrl}/api/products?populate=*`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setProductList(data);
            } catch (error) {
                console.error('Error fetching home data:', error);
            }
        }

        fetchProductBanner();
        fetchProductList();
    }, []);

    return (
        <div className="product_container">
            {productBanner && (
                <div className="product_banner_container">
                    <div className="banner_content">
                        <h1 className="banner_title">{productBanner.data[0].attributes.Banner_Title}</h1>
                        <p className="banner_description">{productBanner.data[0].attributes.Banner_Desc}</p>
                    </div>
                    <img className="product_banner" src={`${globalUrl}${productBanner?.data[0]?.attributes.Banner.data[0].attributes.formats.large.url}`} alt="product banner" /> 
                </div>
            )}
            {productList && (
                <div className="product_list_container">
                    {productList.data.map((product, index) => (
                        <div key={index} className="product_item" onClick={() => handleProductClick(product)}>
                            <img className="product_banner" src={`${globalUrl}${product?.attributes.Product_Main_Img.data[0].attributes.formats.small.url}`} alt={product.attributes.Product_Title} /> 
                            <h2>{product.attributes.Product_Title}</h2>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default Products