import React, { useState, useEffect } from 'react';
import PdfThumbnail from '../Components/PdfThumbnail';
import PdfViewerModal from '../Components/PdfViewerModal';
import '../Styles/Containers/Catalogue.css';

const Catalogue = () => {
    const globalUrl = 'https://testing.faceup.id';
    const [catalogueList, setCatalogueList] = useState(null);
    const [selectedPdfUrl, setSelectedPdfUrl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchCatalogueList = async () => {
            try {
                const response = await fetch(`${globalUrl}/api/catalogues?populate=*`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setCatalogueList(data);
            } catch (error) {
                console.error('Error fetching catalogue data:', error);
            }
        };

        fetchCatalogueList();
    }, []);

    const openPdfViewer = (url) => {
        setSelectedPdfUrl(url);
        setIsModalOpen(true);
    };

    return (
        <div className="catalogue_container">
            {catalogueList && (
                <div className="catalogue_list_container">
                    {catalogueList.data.map((catalogue) => (
                        <div key={catalogue.id} className="catalogue_item">
                            <h2 className='catalogue_title'>{catalogue.attributes.Catalogue_Title}</h2>
                            {catalogue.attributes.Catalogue_Pdf.data.map((pdf) => (
                                <PdfThumbnail
                                    key={pdf.id}
                                    fileUrl={`${globalUrl}${pdf.attributes.url}`}
                                    onClick={() => openPdfViewer(`${globalUrl}${pdf.attributes.url}`)}
                                    className="pdf_thumbnail"
                                />
                            ))}
                        </div>
                    ))}
                </div>
            )}
            {selectedPdfUrl && (
                <PdfViewerModal
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    fileUrl={selectedPdfUrl}
                />
            )}
        </div>
    );
};

export default Catalogue;
