import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfThumbnail = ({ fileUrl, onClick, className }) => {
    return (
        <div onClick={onClick} className={className}>
            <Document file={fileUrl}>
                <Page pageNumber={1} width={200} />
            </Document>
        </div>
    );
};

export default PdfThumbnail;
