import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import '../Styles/Containers/ProductDetail.css';

const ProductDetail = () => {
    const globalUrl = 'https://testing.faceup.id';
    const { state } = useLocation();
    const product = state?.product;

    console.log(product)

    const [selectedImage, setSelectedImage] = useState(
        product?.attributes.Product_Main_Img.data[0].attributes.formats.medium.url
    );

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
    };

    return (
        <div>
            <div className="product_detail_container">
                <h3 className="breadcrumb">
                    {`Home / Products / ${product?.attributes?.Product_Title}`}
                </h3>
                <div className="product_detail_content">
                    <div className="product_mobile_view">
                        <div className="product_detail_images">
                            <img
                                className="product_list"
                                src={`${globalUrl}${product?.attributes.Product_Main_Img.data[0].attributes.formats.small.url}`}
                                alt={product.attributes.Product_Title}
                                onClick={() => handleImageClick(product?.attributes.Product_Main_Img.data[0].attributes.formats.medium.url)}
                            />
                            <img
                                className="product_list"
                                src={`${globalUrl}${product?.attributes.Product_Side_Img1.data[0].attributes.formats.small.url}`}
                                alt={product.attributes.Product_Title}
                                onClick={() => handleImageClick(product?.attributes.Product_Side_Img1.data[0].attributes.formats.medium.url)}
                            />
                            <img
                                className="product_list"
                                src={`${globalUrl}${product?.attributes.Product_Side_Img2.data[0].attributes.formats.small.url}`}
                                alt={product.attributes.Product_Title}
                                onClick={() => handleImageClick(product?.attributes.Product_Side_Img2.data[0].attributes.formats.medium.url)}
                            />
                        </div>
                        <img
                            className="product_detail_selected_image"
                            src={`${globalUrl}${selectedImage}`}
                            alt={product.attributes.Product_Title}
                        />
                    </div>
                    <div className="product_detail_text">
                        <span className="product_detail_title">{product.attributes.Product_Title}</span>
                        <p className="product_detail_desc">{product.attributes.Product_Desc}</p>
                        <p className="product_detail_size">{product.attributes.Product_Size}</p>
                    </div>
                </div>
            </div>
            {product?.attributes?.Product_Colorchart?.data?.length && (
                <div className="product_detail_colorchart">
                    <span className="product_colorchart_title">Colorchart</span>
                    <div className="product_colorchart_banner_container">
                        <img className="product_colorchart_banner" src={`${globalUrl}${product?.attributes.Product_Colorchart.data[0].attributes.formats.large.url}`} alt="product banner" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductDetail;
