import React from 'react';
import Modal from 'react-modal';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PdfViewerModal = ({ isOpen, onRequestClose, fileUrl }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} contentLabel="PDF Viewer" style={{ overlay: { zIndex: 1000 } }}>
            <div style={{ height: '100%' }}>
                <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`}>
                    <Viewer 
                        fileUrl={fileUrl} 
                        plugins={[defaultLayoutPluginInstance]} 
                        defaultScale={SpecialZoomLevel.PageFit} 
                    />
                </Worker>
            </div>
        </Modal>
    );
};

export default PdfViewerModal;
