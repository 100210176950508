import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

// Contaienrs //
import Home from '../Containers/Home';
import Products from '../Containers/Product';
import Colorchart from '../Containers/Colorchart';
import Catalogue from '../Containers/Catalogue';
import ProductDetail from '../Containers/ProductDetail';

function Routers() {
  return (
    <Router>
      <div style={{ position: 'relative', zIndex: 1 }}>
        <Navbar />
        <div style={{ zIndex: '-1', position: 'relative' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Products" element={<Products />} />
            <Route path="/Colorchart" element={<Colorchart />} />
            <Route path="/Catalogue" element={<Catalogue />} />
            <Route path='/ProductDetail/:productTitle' element={<ProductDetail />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default Routers;
