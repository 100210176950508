import React, { useState, useEffect } from "react";
import '../Styles/Containers/Home.css'

const Home = () => {

    const globalUrl = 'https://testing.faceup.id';
    const [homeData, setHomeData] = useState(null);
    const [appLinkData, setAppLinkData] = useState(null);

    useEffect(() => {
        const fetchHomeData = async () => {
            try {
                const response = await fetch(`${globalUrl}/api/home-pages?populate=*`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setHomeData(data);
            } catch (error) {
                console.error('Error fetching home data:', error);
            }
        };

        const fetchAppLink = async () => {
            try {
                const response = await fetch(`${globalUrl}/api/app-links?populate=*`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setAppLinkData(data);
            } catch (error) {
                console.error('Error fetching home data:', error);
            }
        }

        fetchHomeData();
        fetchAppLink();
    }, []);

    const handleOpenLink = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div className="home_container">
            {homeData ?
                <div>
                    {console.log(homeData)}
                    <div>
                        <video autoPlay loop muted className="video_banner">
                            <source src={`${globalUrl}${homeData?.data[0]?.attributes?.VideoBanner?.data[0]?.attributes?.url}`} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div className="video_banner_detail_container">
                            <h2 className="title_video_banner">{homeData?.data[0]?.attributes?.TitleVideoBanner}</h2>
                            <span className="short_desc_video_banner">{homeData?.data[0]?.attributes?.ShortDescVideoBanner}</span>
                            <div className="app_link_buttons">
                                {appLinkData?.data.map((appLink) => (
                                    <button
                                        key={appLink.id}
                                        className="app_link_button"
                                        onClick={() => handleOpenLink(appLink.attributes.Button_Url)}
                                    >
                                        <img className="app_link_image" src={`${globalUrl}${appLink.attributes.Button_Image.data[0].attributes.formats.thumbnail.url}`} alt="Button" />
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="section2_container">
                        <span className="section2_title">{homeData?.data[0]?.attributes?.Section2_Title}</span>
                        <p className="section2_description" >{homeData?.data[0]?.attributes?.Section2_Description}</p>
                    </div>
                    <div className="section3_container">
                        <div className="section3_image_container">
                            <img className="section3_image" src={`${globalUrl}${homeData?.data[0]?.attributes.Section3_Image.data[0].attributes.formats.medium.url}`} alt="Section3" />
                        </div>
                        <div className="section3_detail_container">
                            <span className="section3_title">{homeData?.data[0]?.attributes?.Section3_Title}</span>
                            <p className="section3_description" >{homeData?.data[0]?.attributes?.Section3_Description}</p>
                        </div>
                    </div>
                    <div className="section4_container">
                        <div>
                            <img className="section4_image" src={`${globalUrl}${homeData?.data[0]?.attributes.Section4_Img1.data[0].attributes.formats.thumbnail.url}`} alt="Section4" />
                            <span className="section4_title">{homeData?.data[0]?.attributes?.Section4_Desc1}</span>
                        </div>
                        <div>
                            <img className="section4_image" src={`${globalUrl}${homeData?.data[0]?.attributes.Section4_Img2.data[0].attributes.formats.thumbnail.url}`} alt="Section4" />
                            <span className="section4_title">{homeData?.data[0]?.attributes?.Section4_Desc2}</span>
                        </div>
                        <div>
                            <img className="section4_image" src={`${globalUrl}${homeData?.data[0]?.attributes.Section4_Img3.data[0].attributes.formats.thumbnail.url}`} alt="Section4" />
                            <span className="section4_title">{homeData?.data[0]?.attributes?.Section4_Desc3}</span>
                        </div>
                    </div>
                    <div className="section5_container">
                        <div className="section5_image_container">
                            <img className="section5_image" src={`${globalUrl}${homeData?.data[0]?.attributes.Section5_Image.data[0].attributes.formats.medium.url}`} alt="Section5" />
                        </div>
                        <div className="section5_detail_container">
                            <span className="section5_title">{homeData?.data[0]?.attributes?.Section5_Title}</span>
                            <p className="section5_description" >{homeData?.data[0]?.attributes?.Section5_Description}</p>
                        </div>
                    </div>
                    <div className="section6_container">
                        <div className="section6_detail_container">
                            <span className="section6_title">{homeData?.data[0]?.attributes?.Section6_Title}</span>
                            <p className="section6_description" >{homeData?.data[0]?.attributes?.Section6_Description}</p>
                        </div>
                        <div className="section6_image_container">
                            <img className="section6_image" src={`${globalUrl}${homeData?.data[0]?.attributes.Section6_Image.data[0].attributes.formats.medium.url}`} alt="Section6" />
                        </div>
                    </div>
                    <div className="instagram_container">
                        <div className="instagram_text">
                            <span>{homeData?.data[0]?.attributes?.Bottom_Tagline}</span>
                        </div>
                        <div className="instagram_text2">
                            <span>Instagram</span>
                        </div>
                        <div className="image_grid">
                            <a href="https://www.instagram.com/p/C5Hyen4yRjS/"><img src={require('../Assets/InstagramImageOne.jpeg')} alt="Gambar 1" /></a>
                            <a href="https://www.instagram.com/p/C5PzXntysYb/"><img src={require('../Assets/InstagramImageTwo.jpeg')} alt="Gambar 2" /></a>
                            <a href="https://www.instagram.com/p/C53DUPxyyda/"><img src={require('../Assets/InstagramImageThree.jpeg')} alt="Gambar 3" /></a>
                            <a href="https://www.instagram.com/p/C5732BrSM8q/"><img src={require('../Assets/InstagramImageFour.jpeg')} alt="Gambar 4" /></a>
                            <a href="https://www.instagram.com/p/C5CyABySeKw/"><img src={require('../Assets/InstagramImageFive.jpeg')} alt="Gambar 5" /></a>
                        </div>
                        <button onClick={() => handleOpenLink('https://www.instagram.com/ilvasto_haircolors')} className="stroke_button">{homeData?.data[0]?.attributes?.Instagram_Account}</button>
                    </div>
                </div>
            : null}
        </div>
    )
}

export default Home