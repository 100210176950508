import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Styles/Components/Navbar.css';
import IlvastoLogo from '../Assets/logo_ilvasto_white.png';

function Navbar() {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <nav className={`navbar_container ${location.pathname === '/' ? 'no_bg' : 'with_bg'}`}>
        <div className="menu_toggle" onClick={handleMenuToggle}>
          <div className={`menu_icon ${isMobileMenuOpen ? 'open' : ''}`}></div>
          <div className={`menu_icon ${isMobileMenuOpen ? 'open' : ''}`}></div>
          <div className={`menu_icon ${isMobileMenuOpen ? 'open' : ''}`}></div>
        </div>
        <div className='navbar_logo'>
          <img src={IlvastoLogo} alt="Logo" className="logo_image" />
        </div>
        <div className={`navbar_list ${isMobileMenuOpen ? 'open' : ''}`}>
          <ul>
            <li>
              <Link className='navbar_text' to="/" onClick={() => setIsMobileMenuOpen(false)}>Home</Link>
            </li>
            <li>
              <Link className='navbar_text' to="/Products" onClick={() => setIsMobileMenuOpen(false)}>Products</Link>
            </li>
            <li>
              <Link className='navbar_text' to="/Colorchart" onClick={() => setIsMobileMenuOpen(false)}>Colorchart</Link>
            </li>
            <li>
              <Link className='navbar_text' to="/Catalogue" onClick={() => setIsMobileMenuOpen(false)}>Catalogue</Link>
            </li>
          </ul>
        </div>
      </nav>
      <div className={`sidebar ${isMobileMenuOpen ? 'open' : ''}`}>
        <div className="close_toggle" onClick={handleMenuToggle}>
          <div className="close_icon"></div>
          <div className="close_icon"></div>
        </div>
        <ul>
          <li>
            <Link className='sidebar_text' to="/" onClick={() => setIsMobileMenuOpen(false)}>Home</Link>
          </li>
          <li>
            <Link className='sidebar_text' to="/Products" onClick={() => setIsMobileMenuOpen(false)}>Products</Link>
          </li>
          <li>
            <Link className='sidebar_text' to="/Colorchart" onClick={() => setIsMobileMenuOpen(false)}>Colorchart</Link>
          </li>
          <li>
            <Link className='sidebar_text' to="/Catalogue" onClick={() => setIsMobileMenuOpen(false)}>Catalogue</Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navbar;
