import React, { useState, useEffect } from "react";
import '../Styles/Containers/Colorchart.css'

const Colorchart = () => {

    const globalUrl = 'https://testing.faceup.id';
    const [colorchartBanner, setColorchartBanner] = useState(null);
    const [colorchartList, setColorchartList] = useState(null);

    useEffect(() => {
        const fetchColorchartBanner = async () => {
            try {
                const response = await fetch(`${globalUrl}/api/colorchart-pages?populate=*`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setColorchartBanner(data);
            } catch (error) {
                console.error('Error fetching home data:', error);
            }
        };

        const fetchColorchartList = async () => {
            try {
                const response = await fetch(`${globalUrl}/api/colorcharts?populate=*`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setColorchartList(data);
            } catch (error) {
                console.error('Error fetching home data:', error);
            }
        }

        fetchColorchartBanner();
        fetchColorchartList();
    }, []);

    return (
        <div className="colorchart_container">
            {colorchartBanner && (
                <div className="colorchart_banner_container">
                    <div className="banner_content">
                        <h1 className="banner_title">{colorchartBanner.data[0].attributes.Colorchart_Banner_Title}</h1>
                        <p className="banner_description">{colorchartBanner.data[0].attributes.Colorchart_Banner_Tagline}</p>
                    </div>
                    <img className="colorchart_banner" src={`${globalUrl}${colorchartBanner?.data[0]?.attributes.Colorchart_Banner.data[0].attributes.formats.large.url}`} alt="colorchart banner" />
                </div>
            )}
            {colorchartList && (
                <div className="colorchart_list_container">
                    {colorchartList.data.map((colorchart, index) => (
                        <div key={index} className="colorchart_item">
                            <h2 className="colorchart_list_title">{colorchart?.attributes?.Colorchart_Title}</h2>
                            <p className="colorchart_list_desc">{colorchart?.attributes?.Colorchart_Desc}</p>
                            <div className="colorchart_list_banner_container">
                                <img className="colorchart_list_banner" src={`${globalUrl}${colorchart?.attributes.Colorchart_Img.data[0].attributes.formats.large.url}`} alt={colorchart.attributes.Colorchart_Title} />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default Colorchart