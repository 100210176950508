import React from "react";
import "../Styles/Components/Footer.css"
import IlvastoLogo from '../Assets/logo_ilvasto_white.png';

const Footer = () => {

    const handleOpenLink = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div className="footer_container">
            <div className="footer_first_container">
                <div className="footer_first_row">
                    <div className="footer_ilvasto_container">
                        <div className='footer_ilvasto_logo'>
                            <img src={IlvastoLogo} alt="Ilvasto Logo" className="logo_image_footer" />
                        </div>
                    </div>
                    <div className="social_media">
                        <img onClick={() => handleOpenLink('https://www.instagram.com/sinergia.beaute/')} src={require('../Assets/logo_instagram.png')} alt="Logo" className="logo_image_sosmed" />
                        <img onClick={() => handleOpenLink('https://www.facebook.com/profile.php?id=100054435972198')} src={require('../Assets/logo_facebook.png')} alt="Logo" className="logo_image_sosmed" />
                        <img onClick={() => handleOpenLink('https://www.tiktok.com/@sinergia.id?_t=8i8dW6jJPe6&_r=1')} src={require('../Assets/logo_tiktok.png')} alt="Logo" className="logo_image_sosmed" />
                        <img onClick={() => handleOpenLink('https://www.youtube.com/@shierlynangoy2233')} src={require('../Assets/logo_youtube.png')} alt="Logo" className="logo_image_sosmed" />
                    </div>
                </div>                    
                <div className="footer_second_row">
                    <h3>About</h3>
                    <span>Contact</span>
                </div>
                <div className="footer_third_row">
                    <h3>Help</h3>
                    <span>Shipping & Returns</span>
                    <span>FAQ</span>
                    <span>Terms & Condition</span>
                    <span>Privacy Policy</span>
                </div>
                <div className="footer_forth_row">
                    <h3>We Don't Keep Out Beauty Secrets</h3>
                </div>
            </div>
            <div className="footer_second_container">
                <span>@ 2024 Sinergia Beaute. All rights reserved.</span>
            </div>
        </div>

    )
}

export default Footer